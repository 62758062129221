// Minimum supported resolution: 360x640

$fontFamily: 'Poppins', sans-serif;
$fontFamily-he: 'Assistant', 'sans-serif';
$fontFamily2: 'Roboto', sans-serif;
$pageColor: #f5f5f5;
$backgroundColor: #fff;
$backgroundColorDark: #ddd;
$backgroundColorDarker: #bbb;
$textColor: #000;
$borderColor: #d7d7d7;
$primaryColor: #5bc6f9;
$primaryColorLight: #84d8ff;
$primaryColorDark: #3bb1e7;
$primaryColorVeryLight: rgb(140, 219, 255);
$secondaryColor: #5fcf90;
$secondaryColorLight: #89ebb3;
$secondaryColorDark: #3ab871;
$attentionColor: #e15a5a;
$secondaryColorDesaturated: #a2b3a9;
$secondaryActionColor: #757b89;
$tertiaryColorLight: #969ba8;
$tertiaryColorDark: #626772;
$purpleColor: #762eff;
$greenColor: #5fcf90;
$textOnPrimaryColor: #fff;
$textOnSecondaryColor: #fff;
$textOnTertiaryColor: #fff;
$tonedColor: #ddd;
$tonedColorLight: #fff;
$tonedColorDark: #bbb;
$outlineHighlightColor: #373737;
$headerHeight: 88px;
$mobileHeaderHeight: 88px;
$restaurantDesktopHeaderHeight: 70px;
$contentOuterWidth: 1280px;
$narrowContentWidth: 480px;
$desktopBreakpoint: 852px;
