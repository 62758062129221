@import './globals';

.ms-Link {
    color: $primaryColor;
    font-size: 1em;
    text-align: start;
}

.ms-Shimmer-container {
    font-size: 1em;
}

.ms-StackItem,
.ms-Stack,
.ms-Stack-inner {
    font-size: 1em;
    font-family: $fontFamily;

    &:lang(he) {
        font-family: $fontFamily-he;
    }
}

.ms-TextField-fieldGroup {
    border-radius: 5px;
    font-size: 1em;
    height: auto;

    // Style the input's border-radius so that Chrome's saved form autofill doesn't overflow out of the borders
    .ms-TextField-field {
        border-radius: 5px;
        font-family: $fontFamily;

        &:lang(he) {
            font-family: $fontFamily-he;
        }
    }

    i {
        right: 1em;
        bottom: 0.5em;
        height: 50%;
    }
}

.ms-TextField,
.ms-TextField .ms-Label {
    font-family: $fontFamily;

    &:lang(he) {
        font-family: $fontFamily-he;
    }
}

.ms-TextField.ms-TextField--multiline .ms-TextField-fieldGroup {
    border-radius: 5px;
}

html[dir='rtl'] .ms-TextField-fieldGroup i {
    right: initial;
    left: 1em;
}

.ms-TextField-field {
    font-size: 1em;
    padding: 0.15em 1.75em 0.25em 0.75em;
}

html[dir='rtl'] .ms-TextField-field {
    padding: 0.15em 0.75em 0.25em 1.75em;
}

.ms-TextField-wrapper,
.ms-TextField {
    font-size: 1em;
}

.ms-TextField-errorMessage {
    font-size: 0.75em;
    font-family: $fontFamily;

    &:lang(he) {
        font-family: $fontFamily-he;
    }
}

.ms-MessageBar {
    font-size: 1em;

    .ms-MessageBar-icon {
        font-size: 1em;
        min-width: 1em;
        min-height: 1em;
        margin: 0.5em 0.75em 0.5em 0.5em;
    }

    .ms-MessageBar-text {
        font-size: 0.75em;
        margin: 0.75em 1em 0.75em 0.75em;

        .ms-MessageBar-innerText {
            line-height: 1.5em;
        }
    }
}

html[dir='rtl'] .ms-MessageBar .ms-MessageBar-text {
    margin: 0.75em 0.75em 0.75em 1em;
}

.ms-Label {
    font-size: 0.85em;
    color: $textColor;
    margin-bottom: 0.25em;
    padding: 0.35em 0;
    width: auto;
}

.ms-Slider {
    font-size: 1em;
}

.ms-Checkbox {
    .ms-Checkbox-label {
        font-family: $fontFamily;

        .ms-Checkbox-checkbox {
            border-radius: 0;
        }

        &:lang(he) {
            font-family: $fontFamily-he;
        }
    }

    &:not(.is-checked):hover {
        .ms-Checkbox-checkmark {
            opacity: 0;
        }
    }
}

.ms-Checkbox.is-checked .ms-Checkbox-label .ms-Checkbox-checkbox {
    position: relative;
    border: 3px double $primaryColor;
    background-color: transparent;

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $primaryColor;
        z-index: -1;
    }
}

.ms-Checkbox-label,
.ms-Checkbox-text {
    color: $textColor;
    font-size: 1em;
}

.ms-Button {
    font-size: 0.75em;
    min-height: 2em;
    height: auto;
    padding: 0;
    border: 1px solid $borderColor;
    border-radius: 5px;
    position: relative;
    transition: background-color 0.3s ease 0s;
    font-family: $fontFamily;

    &:lang(he) {
        font-family: $fontFamily-he;
    }

    &.ms-Button--primary,
    &.ms-Button--compoundPrimary {
        background-color: $primaryColor;
        color: $textOnPrimaryColor;
        fill: $textOnPrimaryColor;
        stroke: $textOnPrimaryColor;
        border-color: $primaryColorDark;

        &[disabled=''] {
            background-color: $primaryColorVeryLight;
            color: $backgroundColor;
            fill: $backgroundColor;
            stroke: $backgroundColor;
            border-color: $primaryColorVeryLight;

            .ms-Icon {
                color: $backgroundColor;
                fill: $backgroundColor;
                stroke: $backgroundColor;
            }
        }
    }

    &.ms-Button--default,
    &.ms-Button--compound {
        background-color: $tonedColor;
        color: $textColor;
        fill: $textColor;
        stroke: $textColor;
        border-color: $tonedColorDark;

        &[disabled=''] {
            background-color: #bbb;
            color: $borderColor;
            fill: $borderColor;
            stroke: $borderColor;
            border-color: #bbb;

            .ms-Icon {
                color: $borderColor;
                fill: $borderColor;
                stroke: $borderColor;
            }
        }
    }

    &:hover {
        background: transparent none repeat scroll 0% 0%;

        &.ms-Button--primary,
        &.ms-Button--compoundPrimary {
            background-color: $primaryColorLight;
        }

        &.ms-Button--default,
        &.ms-Button--compound {
            background-color: #bbb;
        }
    }

    &:active {
        &.ms-Button--primary,
        &.ms-Button--compoundPrimary {
            background-color: $primaryColorDark;
        }

        &.ms-Button--default,
        &.ms-Button--compound {
            background-color: $tonedColorDark;
        }
    }

    &.ms-Button--icon {
        border-radius: 50%;
        border: 0 none;

        .ms-Icon {
            font-size: 1em;
        }
    }

    .ms-Button-flexContainer {
        z-index: 1;
        position: relative;
        flex-wrap: wrap-reverse;
        width: 100%;
        height: 100%;
        padding: 0.5em 1em;
        box-sizing: border-box;
        align-content: center; // If button height is less than content height

        .ms-Button-label {
            font-size: 1em;
            font-weight: 500;
        }

        .ms-Button-description {
            font-size: 0.85em;
        }
    }

    .ms-Icon {
        padding-inline-start: 0.33em;
        align-self: center;
        order: 1;
    }

    &.ms-Pivot-link {
        border: 0 none;
        display: inline-block;
        border-radius: 0;
        padding: 0;
        margin-inline-end: 0.25em;
        color: $textColor;

        ::before {
            content: '';
            height: 2px;
            position: absolute;
            bottom: 0;
            transition: left 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s,
                right 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s;
            background-color: transparent;
        }

        &.is-selected {
            ::before {
                background-color: $secondaryColorLight;
                left: 0.5em;
                right: 0.5em;
            }

            &:hover {
                ::before {
                    left: 0;
                    right: 0;
                }
            }
        }

        &:hover {
            background-color: $backgroundColorDark;
        }

        &:active {
            background-color: $backgroundColorDarker;
        }

        .ms-Pivot-linkContent {
            padding: 0 0.5em;
        }
    }
}

.ms-Button-label {
    line-height: initial;
}

.ms-CommandBar {
    font-size: 1em;

    .ms-Button--commandBar {
        font-size: 0.66em;
        border-radius: 0;
        border-color: transparent;

        .ms-Button-flexContainer {
            padding: 0 0.5em;
        }

        &:hover {
            background-color: $backgroundColorDarker;
        }
    }
}

.ms-ContextualMenu-Callout {
    .ms-ContextualMenu {
        font-size: 1em;
        padding: 0.25em 0.5em;

        .ms-ContextualMenu-item {
            font-size: 1em;

            .ms-ContextualMenu-link {
                font-size: 0.66em;
                margin: 0.5em 0;
                height: auto;
                line-height: normal;
                padding: 0;
                text-align: start;

                &:hover {
                    background-color: $backgroundColorDark;
                }
            }
        }
    }
}

.ms-Pivot,
.toolbar {
    font-size: 1em;
}

.ms-Layer,
.ms-Layer-content,
div[role='dialog'],
.ms-Modal {
    font-size: 1em;
}

.ms-Panel .ms-Panel-main {
    z-index: 1;
}

.ms-Dialog-main {
    font-family: $fontFamily;
    border-radius: 0.7333rem;
    z-index: 1;
    min-height: 0;
    min-width: 0;
    max-height: 100%;
    max-width: 100%;

    &:lang(he) {
        font-family: $fontFamily-he;
    }
}

.ms-Dialog-subText,
.ms-Dialog-title {
    font-size: 1em;
}

.ms-Dialog-actions {
    font-size: 1em;
    margin-top: 1.5em;
}

.ms-Dialog-actionsRight,
.ms-Dialog-action {
    font-size: 1em;
}

.ms-Overlay {
    font-size: 1em;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.6);
}

html[dir='rtl'] .ms-ColorPicker {
    .ms-ColorPicker-slider {
        background: rgba(0, 0, 0, 0)
            linear-gradient(
                to left,
                red 0,
                rgb(255, 0, 153) 10%,
                rgb(205, 0, 255) 20%,
                rgb(50, 0, 255) 30%,
                rgb(0, 102, 255) 40%,
                rgb(0, 255, 253) 50%,
                rgb(0, 255, 102) 60%,
                rgb(53, 255, 0) 70%,
                rgb(205, 255, 0) 80%,
                rgb(255, 153, 0) 90%,
                red 100%
            )
            repeat scroll 0% 0%;
    }
}

.ms-Callout-beak,
.ms-Callout-main {
    background-color: #fff;
    color: #000;
}

.ms-TeachingBubble-headline,
.ms-TeachingBubble-subText,
.ms-TeachingBubble-footer {
    color: #000;
}

.ms-Callout {
    font-family: $fontFamily;

    &:lang(he) {
        font-family: $fontFamily-he;
    }
}

.ms-Callout,
.ms-Callout-main,
.ms-Callout-beakCurtain {
    border-radius: 1em;
}

.ms-Callout-main > div {
    display: flex;
    vertical-align: top;
}

.ms-Callout,
.ms-Callout-main,
.ms-TeachingBubble-subText {
    font-size: 1em;
}

.ms-TeachingBubble-headline {
    font-size: 1.25em;
}

.ms-Button.ms-TeachingBubble-secondaryButton {
    background-color: $secondaryColor;
    border-color: transparent;

    .ms-Button-label {
        color: $textOnSecondaryColor;
    }

    &:hover {
        background-color: $secondaryColorLight;
    }

    &:active {
        background-color: $secondaryColorDark;
    }
}

@media screen and (max-width: 852px) {
    .ms-TeachingBubble {
        font-size: 0.75em;
    }
}

.ms-Dropdown {
    font-size: 1em;
    font-family: $fontFamily;

    &:lang(he) {
        font-family: $fontFamily-he;
    }

    .ms-Dropdown-title {
        padding: 0.25em 1.25em 0.25em 1em;
        border-radius: 5px;
        height: auto;
        line-height: initial;
    }
}

html[dir='rtl'] .ms-Dropdown .ms-Dropdown-title {
    padding: 0.25em 1em 0.25em 1.25em;
}

.ms-Dropdown-callout {
    width: auto;

    .ms-Callout-main {
        border-radius: 1em;
        padding: 0.5em;
    }

    .ms-Checkbox {
        border-radius: 5px;
        padding: 0 0.25em;
        margin-top: 0.25em;

        &:active {
            background-color: $secondaryColorLight;
        }

        &.is-checked {
            background-color: $secondaryColor;
        }
    }
}

.ms-ComboBox {
    font-size: 1em;
    padding: 0.25em 2em 0.25em 0.75em;
    color: $textColor;
    background-color: transparent;
    height: auto;
    min-height: 0;

    &::after {
        border-color: #373737;
        border-radius: 5px;
    }

    .ms-ComboBox-Input {
        color: $textColor;
        background-color: transparent;
    }

    .ms-Button--icon {
        width: 2em;

        .ms-Button-flexContainer {
            font-size: 1.25em;
            padding: 0;

            .ms-Icon {
                padding: 0;
            }
        }
    }
}

html[dir='rtl'] .ms-ComboBox {
    padding: 0.25em 0.75em 0.25em 2em;
}
