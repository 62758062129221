@import './globalStyles/fluent.scss';
@import './globalStyles/globals.scss';

html {
    height: 100%;
}

body {
    font-family: $fontFamily;
    font-size: 15px;
    //background-color: $pageColor;
    color: $textColor;
    margin: 0;
    padding: 0;
    min-height: calc(var(--vh, 1vh) * 100);
    height: 100%;

    &:lang(he) {
        font-family: $fontFamily-he;
    }
}

button {
    color: $textColor; /* Workaround iOS15 behavior where buttons get `color:-apple-system-blue`
                        * https://developer.apple.com/forums/thread/690529
                        */
    font-family: $fontFamily;

    &:lang(he) {
        font-family: $fontFamily-he;
    }
}

@media print {
    body {
        background-color: #fff;
    }
}

a {
    color: $primaryColor;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    margin-bottom: 0.25em;
    color: #000;
    font-family: $fontFamily;
    font-size: 1.5333em;
    line-height: 1.8666em;
    font-weight: 300;
    text-align: center;

    &:lang(he) {
        font-family: $fontFamily-he;
    }
}

#root {
    height: 100%;
    font-size: 1em;
}

$titlePaddingTop: 0.5em;

.contentOuter {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: $contentOuterWidth;
    margin: auto;
    box-shadow: 0 0 64px #06162282;
    overflow-y: auto;
    box-sizing: border-box;

    @media screen and (max-width: $contentOuterWidth) {
        box-shadow: none;
    }

    @media print {
        box-shadow: none;
    }
}

.paddedContent {
    padding: 0 1em;
}

.narrowContent {
    width: 100%;
    max-width: $narrowContentWidth;
    margin: 0 auto;
    box-sizing: border-box;

    @media print {
        max-width: 100%;
    }
}

.narrowContent .main {
    @extend .flexColumn;

    flex-grow: 1;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.flexColumn {
    display: flex;
    flex-direction: column;
}

.flexGrow,
.flexColumn.fullHeight .main {
    flex-grow: 1;
}

.fullHeight {
    //height:100%;

    @media screen and (max-width: $desktopBreakpoint) {
        //height:100%;
        //margin-bottom: 160px;
    }
}

.restaurantDesktopFullHeight {
    @media screen and (min-width: $desktopBreakpoint) {
        height: calc(100vh - #{$restaurantDesktopHeaderHeight});
        height: calc(var(--vh, 1vh) * 100 - #{$restaurantDesktopHeaderHeight});
    }
}

.contentImage {
    flex-grow: 1;
    flex-shrink: 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.customIcon svg {
    width: 1em;
    height: 1em;
}

.fallback__loading-state {
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    justify-content: center;
    align-items: center;
}
